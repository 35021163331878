import React from "react";
import { StoreContext } from "../context/store-context.js"
import Button from "./button.js"

const AddToCart = ({ variantId, quantity, available, ...props }) => {

  const { addVariantToCart, loading } = React.useContext(StoreContext)

  function addToCart(e) {
    e.preventDefault()
    addVariantToCart(variantId, quantity)
  }

  return (
    <Button
      className="add-to-cart"
      onClick={ addToCart }
      disabled={ !available || loading }
      { ...props }
      >
        Add to Cart
    </Button>
  )
}

export default AddToCart
