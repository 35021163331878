import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout.js"
import SEO from "../components/seo.js"
import ProductList from "../components/product-list.js"
import AddToCart from "../components/add-to-cart.js"

const ProductTemplate = (props) => {
  const { product } = props.pageContext;
  const variant = product.variants[0];

  const image = getImage(variant.product.images[0])

  return (
    <Layout>
      <SEO title={product.title} />
      <main className="product">
        <div className="product-container">
          <div className="image-wrapper">
            <GatsbyImage
              image={ image }
              alt={ variant.product.images[0].altText ?? product.title }
            />
          </div>
          <div className="details">
            <h1 className="title">{ product.title }</h1>
            <div className="description" dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}></div>
            <div className="cta">
              <AddToCart
                variantId={ variant.storefrontId }
                price={ variant.price }
                quantity={ 1 }
                available={ true }
                />
                <div className="price">{`$${variant.price}`}</div>
              </div>
              {/*
              <div className="fine-print-container">
                <p className="fine-print">Help me take a stand against gun violence. From now until July 4th 100% of my profits from this shop (more than half of every order) will go to Mom's Demand Action NC to help end the cycle of gun violence in America.</p>
                <p className="fine-print">You don't have to buy a print to make a difference. Make a direct donation on the Moms Demand Action website, <Link to="https://momsdemandaction.org" target="_blank">momsdemandaction.org</Link></p>
              </div>
              */
              }
          </div>
        </div>
        <ProductList />
      </main>
    </Layout>
  )
}
export default ProductTemplate
